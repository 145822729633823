import React from "react";
import './App.css';
import io from "socket.io-client";
import gif from "./rose.gif";
import coin from "./NewCoinPNG.png";
import staticRose from "./rose.png";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "USER",
            num: 1,
            gifActive: false,
            socket: io("https://clawcrazy2.skybounddev.com")
        }

        this.animate = this.animate.bind(this);
    }


    componentDidMount() {
        this.state.socket.on("update", data => {
            console.log(data)

            this.animate()

            this.setState({
                username: data.username,
                num: data.num
            })
        });
    }
    
    animate() {
        // Text
        let text = document.getElementById("text")
        text.style.animation = "fontGrow 4s ease-in-out"

        // Coin
        let coin = document.getElementById("coin")
        coin.style.animation = "MoveDown 4s"

        // Rose
        this.setState({gifActive: true})
        setTimeout(() => {
            this.setState({gifActive: false})
            coin.style.animation = ""
            text.style.animation = ""
        }, 4000);          
    }

    render() {
        return (
            <div className="main">
                <h3 id="text"className={this.state.active ? 'userText': 'userText'} >{this.state.username} dropped {this.state.num}x {this.state.num > 1 ? <>coins</>: <>coin</>}</h3>
                {!this.state.gifActive
                    ? <img id="staticRose" className="rose" src={staticRose} alt="rose" />
                    : <img id="rose" className="rose" src={gif} alt="rose" />
                }
                <img id="coin" className="object" src={coin} alt="coin" />
            </div>
        )
    }
}

export default App;
